html {
  font-size: 100%;
}

/*
Font families defined by this CSS:

- "Inter"                  static "traditional" fonts for older web browsers
- "Inter var"              single-axis variable fonts for all modern browsers
- "Inter var experimental" multi-axis variable fonts for modern web browsers

Use like this in your CSS:

  :root { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    :root { font-family: 'Inter var', sans-serif; }
  }

------------------------- static ------------------------- */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Thin.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Thin.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-ThinItalic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-ThinItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-ExtraLight.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-ExtraLight.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-ExtraLightItalic.woff2?v=3.19')
      format('woff2'),
    url('/fonts/inter-web/Inter-ExtraLightItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Light.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Light.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-LightItalic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-LightItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Regular.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Regular.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Italic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Italic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Medium.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Medium.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-MediumItalic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-MediumItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-SemiBold.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-SemiBold.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-SemiBoldItalic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-SemiBoldItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Bold.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Bold.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-BoldItalic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-BoldItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-ExtraBold.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-ExtraBold.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-ExtraBoldItalic.woff2?v=3.19')
      format('woff2'),
    url('/fonts/inter-web/Inter-ExtraBoldItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-Black.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-Black.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-BlackItalic.woff2?v=3.19') format('woff2'),
    url('/fonts/inter-web/Inter-BlackItalic.woff?v=3.19') format('woff');
}

/* ----------------------- variable ----------------------- */

@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-roman.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Regular';
}

@font-face {
  font-family: 'Inter var';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('/fonts/inter-web/Inter-italic.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Italic';
}

/* ----------- experimental multi-axis variable -----------

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single-file variable font is opt-in rather than the default.
When using this, you will likely need to set font-variation-settings explicitly, e.g:
  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }
*/

@font-face {
  font-family: 'Inter var experimental';
  font-style: oblique 0deg 10deg;
  font-weight: 100 900;
  font-display: swap;
  src: url('/fonts/inter-web/Inter.var.woff2?v=3.19') format('woff2');
}

/* Legacy name (became legacy on Feb 2, 2019) */
@font-face {
  font-family: 'Inter var alt';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  font-display: swap;
  src: url('/fonts/inter-web/Inter-roman.var.woff2?v=3.19') format('woff2');
}
@font-face {
  font-family: 'Inter var alt';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  font-display: swap;
  src: url('/fonts/inter-web/Inter-italic.var.woff2?v=3.19') format('woff2');
}

.tox.tox-tinymce-aux {
  position: relative !important;
  z-index: 1600;
}

.swiper-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.swiper {
  width: 100%;
}

.thumb-swiper {
  box-sizing: border-box;
  width: 100%;
}

.thumb-swiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper .swiper-button-disabled,
.thumb-swiper .swiper-button-disabled {
  opacity: 0 !important;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next,
.thumb-swiper .swiper-button-prev,
.thumb-swiper .swiper-button-next {
  /* display: none; */
  pointer-events: none;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.grecaptcha-badge {
  visibility: hidden;
}
